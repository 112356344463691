// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }
.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}
.image-upload-height {
  min-height: 250px;
}
.image-upload {
  border: 2.5px dashed #3798fc;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
  &.error {
    border-color: #f64e60;
  }
  p {
    margin-top: 25px;
    color: #3798fc;
  }
  svg {
    transform: scale(3);
  }
}
.image-upload-prev {
  width: 80%;
  margin: 0px auto;
  display: block;
  img {
    width: 100%;
    display: block;
    max-width: 150px;
    margin: 0px auto;
  }
  .image-upload-prev-footer {
    margin-top: 15px;
    button {
      margin: 0px 3px;
      background: none;
      border: none;
      border: 1px solid;
      &:hover {
        background-color: #3798fc;
        color: #fff;
      }
    }
  }
}
.image-error-span {
  color: #f64e60;
}
div.autocomplete {
  &.is-invalid {
    > div:first-child {
      border-color: #f04e5f;
    }
  }
}
.table-image {
  width: 50px;
}
.card.card-custom > .card-body.contains-tabs {
  padding-top: 0px !important;
  .MuiPaper-root.MuiPaper-elevation1 {
    margin: 0px -2.25rem;
    padding-top: 20p;
    margin-bottom: 15px;
  }
  .MuiTab-wrapper {
    font-size: 13px;
  }
}
.product-discounts {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 250px;
  font-size: 14px;
}
.font-weight-600 {
  font-weight: 600;
}
span.rs {
  color: #1e3688;
  font-weight: 800;
}
div#kt_header_mobile {
  img {
    width: 100px;
  }
}
.table-avatar-container {
  .symbol.symbol-100 {
    .symbol-label {
      width: 50px;
      height: 50px;
      text-align: center;
      overflow: hidden;

      img {
        height: 100%;
        max-width: 50px;
        pointer-events: none;
      }
    }
  }
}
span.user-status {
  background-color: #0072ff;
  font-weight: 500;
  padding: 1px 11px;
  border-radius: 5px;
  font-size: 11px;
  color: #fff;
  display: block;
  text-align: center;
  &.reject {
    background: #ff8d00;
  }
}
div#kt_header_menu {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #494b74;
  p {
    margin: 0;
  }
}
div.btn.btn-icon.logout-btn {
  height: 100%;
}
div.revenue-div {
  display: flex;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  font-size: 13px;
  font-weight: 500;
  margin-left: 20px;
  span.amount-span {
    font-weight: 600;
    margin-left: 10px;
    color: #1a1a27;
  }
}
div.date-filter {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.react-datepicker-wrapper {
  display: block !important;
}

.image-uploader-container {
  position: relative;
  @media (min-width: 992px) {
    .image-wrapper {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
.MuiPickersBasePicker-container {
  .MuiPickersToolbar-toolbar {
    background-color: #5c509d;
    .MuiPickersToolbarText-toolbarTxt {
      color: #fff;
      &.MuiPickersTimePickerToolbar-ampmLabel {
        color: #827f7fc7;
        &.MuiPickersToolbarText-toolbarBtnSelected {
          color: #fff;
        }
      }
    }
  }
  .MuiPickersClockPointer-pointer {
    background-color: #5c509d;
  }
  .MuiPickersClockPointer-noPoint {
    background-color: #5c509d;
  }
  .MuiPickersClockPointer-thumb {
    border: 14px solid #5c509d;
  }
  .MuiPickersClockNumber-clockNumberSelected {
    color: #fff;
  }
  .MuiPickersClock-pin {
    background-color: #5c509d;
  }
}
.form-group {
  .MuiFormLabel-root.Mui-error {
    color: #f64e60;
  }
  .MuiFormHelperText-root.Mui-error {
    color: #f64e60;
  }
  .MuiInput-underline.Mui-error:after {
    border-bottom-color: #f64e60;
  }
}
.dispatch-filter-row {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  margin-top: 16px;
  div.coloums {
    width: 100%;
    margin: 0px 10px 0 0px;
  }
  // .col-6.first{
  //   padding: 0;
  // }
  .btn-ctnr {
    display: flex;
    // flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    min-width: 160px;
    button {
      &.btn {
        padding: 5px 14px;
        font-size: 10px;
      }
      .btn-primary-light {
        background-color: #ccc;
      }
    }
  }
}

.res-circle {
  width: 30%;
  border-radius: 50%;
  line-height: 0;
  position: relative;
  background: #b3a8a1;
}
 
.circle-txt {
  // position: absolute;
  bottom: 50%;
  width: 100%;
  text-align: center;
  font-family: arial, sans-serif;
  font-weight: bold;
}

.circle {
  display: inline-block;
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
  padding: 5px;
  background: #52434378;
  color: #000000;
  text-align: center;
  line-height: 1;
  box-sizing: content-box;
  white-space: nowrap;
}
.circle:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  padding-top: 100%;
  height: 0;
}
.circle span {
  display: inline-block;
  vertical-align: middle;
}


span.table-chip {
  padding: 2px 12px;
  background: #f15900;
  border-radius: 7px;
  color: #fff;
  font-weight: 600;
}
span.table-chip-order-status{
  padding: 2px 12px;
  border-radius: 7px;
  color: #fff;
  font-weight: 600;
  background: #b3a8a1;
}
button.dispatch-orders.btn {
  position: absolute;
  /* left: 0; */
  top: 5px;
  right: 2.25em;
  padding: 3px 20px;
  // margin-top: 10px;
  margin-right: -15px;
  background: #2a9e06;
  border-color: #2a9e06;
  transition: 0.1s;
}
div.progress-font{
  font-size: 8px;
}
div.driver-select {
  min-width: 350px;
}
.assign-driver {
  .MuiDialog-paper {
    overflow: visible;
  }
}
// MuiTypography-root MuiPickersToolbarText-toolbarTxt MuiPickersToolbarText-toolbarBtnSelected MuiTypography-h2

// <h6 class="MuiTypography-root MuiPickersToolbarText-toolbarTxt MuiPickersTimePickerToolbar-ampmLabel MuiPickersToolbarText-toolbarBtnSelected MuiTypography-subtitle1">AM</h6>

h1, p {
  font-family: Lato;
}
table {
  font-family: arial, sans-serif;
  // border-collapse: collapse;
  width: 100%;
}

td, th {
  // border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

._7ahQImyV4dj0EpcNOjnwA {
  background: #ada6ca4a !important;
  color: #000000;
}

.lhyQmCtWOINviMz0WG_gr {
  background: #ada6ca4a !important;
  color: #000000;
}

._3vt7_Mh4hRCFbp__dFqBCI li:hover {
  background: #ada6ca4a !important;
  color: #000000;
}

._2iA8p44d0WZ-WqRBGcAuEV {
  border: 1px solid #e4e6ef !important;
}

.input-error {
  border: 1px solid #e64a5f !important;
}

.image-error-span {
  color: #f64e60 !important;
}

.search-wrapper {
  border: 1px solid #ec5073;
}

.search-input {
  border-radius: 0.3rem !important;
  border: 1.3px solid rgb(221, 221, 221) !important;
}

.react-search-field-input{
  width: 200px !important;
}




@media print{
	.hide-on-print{
		display: none;
	}
}

@media print {
  html, body { height: initial !important; overflow: initial !important; }
  @page { size: auto; margin: 0; }
}

// @page {
//   size: auto;
//   margin: 20mm;
// }
hr.new2 {
  border-top: 1px dashed;
}

.article-container {
  width: 70%;
  margin: 0 auto;
}

.article {
  padding: 10px;
}

.article_header {
  padding: 10px;
}

.article_route {
  position: relative;
  top: 35px;
  left: 50px;
}

.print-font{
  font-size: 20px;
}

.print-head{
  text-align: center;
  padding-top: 30px;
  padding-bottom: 10px;
}

.MuiDialog-paperWidthSm {
  width : 700px
}


.modal.show .modal-dialog{
  width: 100%;
  height: 100vh;
  margin: 0;
  max-width: 100%;
  min-width: 100vh;
}

.csm-df{
  display: flex;
}

.csm-jcc{
  justify-content: center;
}

.csm-aic{
  align-items: center;
}

.csm-jsa{
  justify-content: space-around;
}

.csm-pos-rel{
  position: relative;
}

.csm-w70p{
  width: 70%;
}

.csm-w73p{
  width: 73%;
}

.csm-ml-50{
  margin-left: 50px;
}

.csm-m0{
  margin: 0;
}

.csm-hidden{
  opacity: 0;
}

.csm-gap-5{
  gap: 5px;
}

.csm-grid{
  display: grid;
}

.csm-jic{
  justify-items: left;  
}

.csm-gtc-3{
  grid-template-columns: repeat(3, 1fr);
}

.csm-gc-start-2{
  grid-column-start: 2;

}

.csm-custom-positioning{
  position: relative;
  right: 50px;
}

.csm-81p{
  width: 81%;
}

.csm-ml-auto{
  margin-left: auto;
}

.csm-mt-30{
  margin-top: 30px;
}